import { breakpoints } from './mq';

export const color = {
  offWhite: '#EFE8D5',
  white: '#FFFFFF',
  black: '#1C243A',
  green: '#4ba068',
  blue: '#409fc6',
  lightBlue: '#B8E1E9',
};

export const font = {
  body: `'Open Sans', Sans-Serif`,
  heading: `'Shrikhand', Times;
  font-weight: 400;`,
  size: {
    xs: '12px',
    sm: '14px',
    md: '18px',
    lg: '44px',
    xl: '78px',
    xxl: '120px',
    giant: '157px',
    h1: '60px',
    h1Tablet: '40px',
    h1Mobile: '26px',
    h2: '36px',
    h2Mobile: '24px',
    h3Mobile: '21px',
  },
};

export const theme = {
  font,
  color,
  breakpoints,
};

type Theme = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}
