import breakpoint from 'styled-components-breakpoint';

export const breakpoints = {
  mobile: 640,
  tablet: 737,
  desktop: 1195,
};

export type Breakpoint = keyof typeof breakpoints;

// note: adding @media prefix was failing, not sure why
export const mq = {
  // we are mobile-first so this breakpoint means mobile-only.
  // mobile includes phone on landscape. However be aware that in the
  // scrolling code landscape is treated as desktop in some cases, by design.
  mobile: `(max-width: ${breakpoints.mobile}px),
  (max-height: ${breakpoints.mobile}px) and (orientation: landscape)`,

  // tablet-or-above, desktop-or-above
  tablet: `(min-width: ${breakpoints.tablet}px),
  (min-height: ${breakpoints.tablet}px) and (orientation: landscape)`,

  desktop: `(min-width: ${breakpoints.desktop}px)`,

  // special lookup for landscape orientation
  landscape: `(orientation: landscape)`,
};

export const mobile = breakpoint('mobile');
export const tablet = breakpoint('tablet');
export const desktop = breakpoint('desktop');
