import { Stats } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React, { FC, ReactNode } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { tablet } from '~/config/mq';
import { theme } from '~/config/theme';
import { SEO } from './SEO';

// https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
const GlobalStyle = createGlobalStyle<{
  isMenuOpen?: boolean;
  isPreloaderShowing?: boolean;
}>`
    * {
      box-sizing: border-box;
    }
    html {
      scroll-behavior: smooth;
      height: 100%;
      height: -webkit-fill-available;
    }
    body {
      background-color: ${({ theme }) => theme.color.lightBlue};
      font-family: ${({ theme }) => theme.font.body};
      color: ${({ theme }) => theme.color.black};
      margin: 0;
      overflow-y: ${(props) =>
        props.isMenuOpen || props.isPreloaderShowing ? 'hidden' : 'auto'};
      min-height: 100vh;
      min-height: -webkit-fill-available;
      ${(props) =>
        props.isMenuOpen
          ? 'height: 100vh; height: -webkit-fill-available'
          : ''};
    }

    // Global fix for broken anti-aliasing on scaled text elements
    .Plx {
      // PLX sets will-change, but it appears to break antialiasing
      will-change: unset !important;
    }

    // important: prevents inactive Plx views from blocking the rest of the site!
    .Plx:not(.Plx--active) {
      pointer-events: none;
    }

    a {
      color: ${({ theme }) => theme.color.blue};
      text-decoration: none;
      font-family: 'Open Sans';
      font-weight: bold;
      font-size: ${({ theme }) => theme.font.size.sm};
      line-height: ${({ theme }) => theme.font.size.md};
      ${tablet`
          font-size: 16px;
      `};
    }

    a:visited {
      color: ${({ theme }) => theme.color.blue};
    }

    a:hover {
      color: ${({ theme }) => theme.color.green};
    }

    #fact-1 h1, #fact-2 h1, #fact-0 button, #fact-1 button {display:none;}

    #fact-2 {margin-bottom:70!important}
    
    /* keeping this around until we know we don't need it, this was thought to fix
       antialiasing, but check the text of know the facts after scaling to be sure.
     div, h1, h2, h3, h4, h5, h6, p, span {
      backface-visibility: hidden;
    } */
  `;

type LayoutProps = {
  showFPS?: boolean;
  isMenuOpen?: boolean;
  isPreloaderShowing?: boolean;
  children?: ReactNode;
};

export const Layout: FC<LayoutProps> = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SEO />
      <GlobalStyle isMenuOpen={props.isMenuOpen} />

      {props.children}

      {props.showFPS && (
        <Canvas style={{ pointerEvents: 'none' }}>
          <Stats showPanel={0} className="stats" />
        </Canvas>
      )}
    </ThemeProvider>
  );
};
