import { graphql, useStaticQuery } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

// gatsby&yall starter: update this using full remote paths!
const ogImage = 'https://areyallgreen.com/images/areyallgreen-share.png';
const ogUrl = 'https://areyallgreen.com/';

type SEOProps = {
  title?: string;
  description?: string;
  keywords?: string[];
};

export const SEO: FC<SEOProps> = ({ title, description, keywords = [] }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `,
  );

  const response = site?.siteMetadata;
  const metaTitle = title || response?.title;
  const metaDescription = description || response?.description;
  const metaAuthor = response?.author || '';

  return (
    <Helmet
      htmlAttributes={{ lang: 'en' }}
      title={metaTitle}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: metaTitle,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:url`,
          content: ogUrl,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: metaAuthor,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: metaDescription,
        },
      ].concat(
        keywords.length > 0
          ? {
              name: 'keywords',
              content: keywords.join(', '),
            }
          : [],
      )}
    >
      {/* Scripts here - browser-only scripts should be gated with !isSSG && !isNetlify */}
    </Helmet>
  );
};
